import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);
app.config.productionTip = false;

import router from "./router";
app.use(router);

//Componentes
import LoadingXs from "./components/LoadingXs.vue";
app.component("LoadingXs", LoadingXs);

import LoadingFull from "./components/LoadingFull.vue";
app.component("LoadingFull", LoadingFull);

import { h } from "vue";
app.component("svg-tag", {
  render() {
    return h(this.tag, {}, this.$slots.default());
  },
  props: {
    tag: {
      type: String,
      required: true,
    },
  },
});

//InputNumber
import VueNumberInput from "@chenfengyuan/vue-number-input";
app.component(VueNumberInput.name, VueNumberInput);

// Axios
import axios from "axios";
import VueAxios from "vue-axios";
app.use(VueAxios, axios);

//Notificaciones
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
app.use(VueSweetalert2, { position: "top-end", timer: 5000, toast: true });

//Configuración para formato moneda
app.config.globalProperties.filters = {
  formatMoney(value) {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      maximumSignificantDigits: 21,
    }).format(value);
  },
};

//URL de API
//app.config.globalProperties.baseUrl = "http://localhost:3002/";
app.config.globalProperties.baseUrl = "https://api.taqui.store/";

app.mount("#app");
