import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    beforeEnter() {
      window.location.href = "https://taqui-co.com";
    },
  },
  {
    path: "/event/:idEvento",
    name: "Event",
    component: () => import("../views/EventView.vue"),
    children: [
      {
        path: "/typeTicket/:idEvento",
        name: "TypeTicket",
        component: () => import("../views/TypeTicketView.vue"),
        meta: {
          title: "Tipo de entrada | TaquiShop",
        },
      },
      {
        path: "/location/:idEvento",
        name: "Location",
        component: () => import("../views/LocationView.vue"),
        meta: {
          title: "Localidad | TaquiShop",
        },
      },
      {
        path: "/locationV2/:idEvento",
        name: "LocationV2",
        component: () => import("../views/LocationV2View.vue"),
        meta: {
          title: "Localidad | TaquiShop",
        },
      },
      {
        path: "/cart/:idEvento",
        name: "Cart",
        component: () => import("../views/CartView.vue"),
        meta: {
          title: "Resumen | TaquiShop",
        },
      },
      {
        path: "/cartV2/:idEvento",
        name: "CartV2",
        component: () => import("../views/CartV2View.vue"),
        meta: {
          title: "Resumen | TaquiShop",
        },
      },
    ],
  },
  {
    path: "/cupones/:idEvento",
    name: "Cupones",
    component: () => import("../views/CuponesView.vue"),
    meta: {
      title: "Cupones | TaquiShop",
    },
  },
  {
    path: "/promo/:code",
    name: "Promo",
    component: () => import("../views/PromoView.vue"),
    meta: {
      title: "Promoción | TaquiShop",
    },
  },
  {
    path: "/auth/:idReserva",
    name: "Auth",
    component: () => import("../views/AuthView.vue"),
    meta: {
      title: "Inicia sesión | TaquiShop",
    },
  },
  {
    path: "/forms/:idReserva",
    name: "Forms",
    component: () => import("../views/FormsView.vue"),
    meta: {
      title: "Formularios | TaquiShop",
    },
  },
  {
    path: "/delivery/:idReserva",
    name: "Delivery",
    component: () => import("../views/DeliveryView.vue"),
    meta: {
      title: "Entrega | TaquiShop",
    },
  },
  {
    path: "/checkout/:idReserva",
    name: "Checkout",
    component: () => import("../views/CheckoutView.vue"),
    meta: {
      title: "Confirmación | TaquiShop",
    },
  },
  {
    path: "/status/:idReserva",
    name: "Status",
    component: () => import("../views/StatusView.vue"),
    meta: {
      title: "Estado | TaquiShop",
    },
  },
  {
    path: "/ticket/:idReserva",
    name: "Ticket",
    component: () => import("../views/TicketView.vue"),
    meta: {
      title: "Descarga tus entradas | TaquiShop",
    },
  },

  //Rutas para abonos
  {
    path: "/seasonTicket/:idGrupo",
    name: "seasonTicket",
    component: () => import("../views/SeasonTicketView.vue"),
    children: [
      {
        path: "/seasonTypeTicket/:idGrupo",
        name: "SeasonTypeTicket",
        component: () => import("../views/SeasonTypeTicketView.vue"),
        meta: {
          title: "Tipo de entrada | TaquiShop",
        },
      },
      {
        path: "/seasonTypeBook/:idGrupo",
        name: "SeasonTypeBook",
        component: () => import("../views/SeasonTypeBookView.vue"),
        meta: {
          title: "Modalidad de compra | TaquiShop",
        },
      },
      {
        path: "/seasonLocationAuto/:idGrupo",
        name: "SeasonLocationAuto",
        component: () => import("../views/SeasonLocationAutoView.vue"),
        meta: {
          title: "Localidad | TaquiShop",
        },
      },
      {
        path: "/seasonLocation/:idGrupo",
        name: "SeasonLocation",
        component: () => import("../views/SeasonLocationView.vue"),
        meta: {
          title: "Localidad | TaquiShop",
        },
      },
      {
        path: "/seasonCart/:idGrupo",
        name: "SeasonCart",
        component: () => import("../views/SeasonCartView.vue"),
        meta: {
          title: "Resumen | TaquiShop",
        },
      },
    ],
  },
  //Rutas para abonos
  {
    path: "/seasonCoupons/:idGrupo",
    name: "SeasonCoupons",
    component: () => import("../views/SeasonCouponsView.vue"),
    meta: {
      title: "Cupones | TaquiShop",
    },
  },
  {
    path: "/seasonAuth/:idAbono",
    name: "SeasonAuth",
    component: () => import("../views/SeasonAuthView.vue"),
    meta: {
      title: "Inicia sesión | TaquiShop",
    },
  },
  {
    path: "/seasonDelivery/:idAbono",
    name: "SeasonDelivery",
    component: () => import("../views/SeasonDeliveryView.vue"),
    meta: {
      title: "Entrega | TaquiShop",
    },
  },
  {
    path: "/seasonCheckout/:idAbono",
    name: "SeasonCheckout",
    component: () => import("../views/SeasonCheckoutView.vue"),
    meta: {
      title: "Confirmación | TaquiShop",
    },
  },
  {
    path: "/seasonTicketDownload/:idAbono",
    name: "SeasonTicketDownload",
    component: () => import("../views/SeasonTicketDownloadView.vue"),
    meta: {
      title: "Descarga tus entradas | TaquiShop",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorView",
    beforeEnter() {
      window.location.href = "https://taqui-co.com";
    },
  },

  //Rutas para transacciones
  {
    path: "/authTransaction/:idTransaccion",
    name: "AuthTransaction",
    component: () => import("../views/AuthTransactionView.vue"),
    meta: {
      title: "Inicia sesión | TaquiShop",
    },
  },
  {
    path: "/deliveryTransaction/:idTransaccion",
    name: "DeliveryTransaction",
    component: () => import("../views/DeliveryTransactionView.vue"),
    meta: {
      title: "Entrega | TaquiShop",
    },
  },
  {
    path: "/checkoutTransaction/:idTransaccion",
    name: "CheckoutTransaction",
    component: () => import("../views/CheckoutTransactionView.vue"),
    meta: {
      title: "Confirmación | TaquiShop",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? " TaquiShop";
});

export default router;
